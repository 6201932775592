import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Root from "Root";
import Dashboard from "pages/dashboard/Dashboard";
import Statistic from "pages/statistic/Statistic";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./index.scss";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: "Not Found",
    children: [
      {
        path: "/",
        element: <Dashboard />,
      },
      // {
      //   path: "send-list",
      //   element: <SendList />,
      // },
      // {
      //   path: "member-list",
      //   element: <MemberList />,
      // },
      {
        path: "send-stats",
        element: <Statistic />,
      },
    ],
  },
]);

const queryClient = new QueryClient();

root.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <RouterProvider router={router} />
  </QueryClientProvider>
  // </React.StrictMode>
);
