import { PageTitle } from "components/PageTitle";
import DataAmount from "components/statistic/DataAmount";
import ResultList from "components/statistic/ResultList";
import Search from "components/statistic/Search";
import st from "./Statistic.module.scss";

const Statistic = () => {
  return (
    <div className={st.layout}>
      <PageTitle title={"발송통계"} />
      <DataAmount />
      <Search />
      <ResultList />
    </div>
  );
};

export default Statistic;
