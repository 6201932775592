import { TableGetData, TableRebuiltData } from "types/statistic/tableData";

export const manipulateData = (datas: any, page: number) => {
  const newDataArr: TableRebuiltData[] = [];

  datas?.content.map((item: TableGetData, idx: number): void => {
    const newItem: TableRebuiltData = {
      no: idx + 1 + (page - 1) * 10,
      sendDate: item.sendDate.replace("T", " "),
      sendCompany: item.sendCompany,
      sendKeyword: item.sendKeyword,
      mailTitle: item.mailTitle,
      mailTotal: item.mailTotal.toLocaleString(),
      isSucceed: `${item.succMailTotal.toLocaleString()}/${item.failMailTotal.toLocaleString()}`,
      type: item.type === "0" ? "즉시" : "예약",
      report: "",
      mailNum: item.mailNum,
    };

    newDataArr.push(newItem);
  });

  return newDataArr;
};
