import { LoginTypes } from "types/login/login";
import { create } from "zustand";

const useLoginStore = create<LoginTypes>((set) => ({
  userId: "",
  setUserId: (userId) => set({ userId }),
  userPw: "",
  setUserPw: (userPw) => set({ userPw }),
  isMouseOn: "",
  setIsMouseOn: (isMouseOn) => set({ isMouseOn }),
  isChecked: false,
  setIsChecked: () => set((state) => ({ isChecked: !state.isChecked })),
  showPw: false,
  setShowPw: () => set((state) => ({ showPw: !state.showPw })),
}));

export default useLoginStore;
