import st from "./DataAmountBox.module.scss";

interface Props {
  title: string;
  value: number | "-";
}

export const DataAmountBox = ({ title, value }: Props) => {
  return (
    <div className={st.amount_box}>
      <p>{title}</p>
      <span>{value?.toLocaleString()}건</span>
    </div>
  );
};
