import { RecentListData } from "types/dashboard/recentList";

export const recentListData = (data: RecentListData[], isError: boolean) => {
  if (isError) {
    const newData: RecentListData[] = [
      {
        sendDate: "-",
        sendCompany: "-",
        groupName: "-",
        mailTitle: "-",
        mailTotal: "-",
        succMailTotal: "-",
        failMailTotal: "-",
      },
    ];

    return newData;
  } else {
    const newData: RecentListData[] = [];

    for (let index = 0; index < data.length; index++) {
      if (data) {
        const item = {
          sendDate: data[index]!.sendDate.split("T")[0]!,
          sendCompany: data[index]!.sendCompany,
          groupName: data[index]!.groupName,
          mailTitle: data[index]!.mailTitle,
          mailTotal: `${data[index]!.mailTotal.toLocaleString()}건`,
          succMailTotal: `${data[index]!.succMailTotal.toLocaleString()}건`,
          failMailTotal: `${data[index]!.failMailTotal.toLocaleString()}건`,
        };

        newData.push(item);
      }
    }

    return newData;
  }
};
