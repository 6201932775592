import { PageTitle } from "components/PageTitle";
import { Information } from "components/dashboard/container/Information";

import { RecentList } from "components/dashboard/container/RecentList";
import st from "./Dashboard.module.scss";

const Dashboard = () => {
  return (
    <section className={st.dashboard}>
      <PageTitle title={"대시보드"} />
      <Information />
      <RecentList />
    </section>
  );
};

export default Dashboard;
