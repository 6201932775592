import { LoginInputFormTypes } from "types/login/login";
import useLoginStore from "stores/login/useLoginStore";
import Visible from "assets/svg/visible.svg";
import Invisible from "assets/svg/invisible.svg";

import st from "components/login/LoginInputForm.module.scss";

const LoginInputForm = ({
  type,
  placeholder,
  keyDown,
  onChange,
}: LoginInputFormTypes) => {
  const { showPw, setShowPw } = useLoginStore();

  const inputType = type === "password" && showPw ? "text" : type;

  return (
    <div className={st.input_container}>
      <input
        type={inputType}
        placeholder={placeholder}
        className={st.input_style}
        onKeyDown={(e) => keyDown && keyDown(e)}
        onChange={onChange}
        autoComplete="off"
      />
      {type === "password" && (
        <img
          src={showPw ? Visible : Invisible}
          alt="비밀번호 숨김 여부"
          onClick={() => setShowPw(!showPw)}
          className={st.visible_icon}
        />
      )}
    </div>
  );
};

export default LoginInputForm;
