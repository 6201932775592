import usePostApi from "api/usePostApi";
import loginLogo from "assets/image/loginLogo.png";
import AutoLogin from "components/login/AutoLogin";
import LoginInputForm from "components/login/LoginInputForm";
import { ChangeEvent, KeyboardEvent, useEffect } from "react";
import useLoginStore from "stores/login/useLoginStore";
import { setCookie } from "utilities/cookie";

import st from "pages/login/Login.module.scss";

const Login = () => {
  const { userId, setUserId, userPw, setUserPw, isChecked } = useLoginStore();

  const loginForm = [
    {
      type: "text",
      placeholder: "아이디",
      setInput: setUserId,
    },
    {
      type: "password",
      placeholder: "비밀번호",
      setInput: setUserPw,
    },
  ];

  const { data, isError, error, mutate } = usePostApi("login", "/login", {
    id: userId,
    password: userPw,
  });

  useEffect(() => {
    if (data?.token) {
      const maxAge = isChecked ? 3600 * 24 * 7 : 3600 * 24;
      setCookie("loginToken", data.token, { maxAge });
      window.location.href = "/";
    } else if (isError) {
      console.log(error);
      alert("아이디 또는 비밀번호가 일치하지 않습니다. 다시 시도해주세요");
    }
  }, [data, error]);

  const pressEnterKey = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      mutate();
    }
  };

  const handleChange =
    (updateState: (value: string) => void) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      updateState(e.target.value);
    };

  return (
    <div className={st.login_page}>
      <img className={st.logo} src={loginLogo} alt="logo" />
      <form className={st.form} autoComplete="off">
        {loginForm.map((item) => (
          <LoginInputForm
            key={item.placeholder}
            type={item.type}
            placeholder={item.placeholder}
            keyDown={pressEnterKey}
            onChange={handleChange(item.setInput)}
          />
        ))}
        <AutoLogin />
      </form>
      <button className={st.login_btn} onClick={() => mutate()}>
        로그인
      </button>
      <span>@ 2024 Unioncontents, Inc. All rights reserved.</span>
    </div>
  );
};

export default Login;
