import { CustomTable } from "components/CustomTable";
import { TableColumnProps } from "types/customTable";

import useGetApi from "api/useGetApi";
import { Paging } from "components/common/Paging";
import { useEffect, useRef } from "react";
import useMailNumStore from "stores/statistic/useMailNumStore";
import usePageStore from "stores/statistic/usePageStore";
import { TableRebuiltData } from "types/statistic/tableData";
import { openDialog } from "utilities/common/openDialog";
import { manipulateData } from "utilities/statistic/manipulatedData";
import st from "./ResultList.module.scss";
import ReportDialog from "./modal/ReportDialog";
import Report from "./table/Report";

const ResultList = () => {
  const dialogRef = useRef<HTMLDialogElement>(null);
  const { page, setPage } = usePageStore();
  const { mailNum, setMailNum } = useMailNumStore();
  const URL = `/mail-send-list?size=10&page=${page - 1}`;
  const { data, refetch } = useGetApi("resultList", URL);

  const columns: TableColumnProps[] = [
    {
      key: "no",
      label: "NO",
      flexGrow: 0.6,
    },
    {
      key: "sendDate",
      label: "발송날짜",
      flexGrow: 1,
    },
    {
      key: "sendCompany",
      label: "회사명",
      flexGrow: 1,
    },
    {
      key: "sendKeyword",
      label: "키워드",
      flexGrow: 1,
    },
    {
      key: "mailTitle",
      label: "제목",
      flexGrow: 2.5,
    },
    {
      key: "mailTotal",
      label: "발송건",
      flexGrow: 0.7,
    },
    {
      key: "isSucceed",
      label: "성공/실패",
      flexGrow: 1,
    },
    {
      key: "type",
      label: "발송타입",
      flexGrow: 1,
    },
    {
      key: "report",
      label: "통계보고서",
      isCustomCell: true,
      handleClick: (id: string) => {
        openDialog(dialogRef);
        setMailNum(id);
      },
      flexGrow: 1,
    },
  ];

  const rebuiltData = manipulateData(data, page);

  const filteredItem: TableRebuiltData = rebuiltData.filter(
    (item) => item.mailNum === mailNum
  )[0]!;

  useEffect(() => {
    refetch();
  }, [page !== 1 && page]);

  return (
    <>
      <div className={st.table_container}>
        <CustomTable
          datas={rebuiltData && rebuiltData}
          columns={columns}
          rowKey={"mailNum"}
        >
          <Report key={"report"} />
        </CustomTable>
        <Paging
          total={data?.totalPages}
          limit={0}
          activePage={page}
          onChangePage={setPage}
        />
      </div>
      <ReportDialog ref={dialogRef} datas={filteredItem} />
    </>
  );
};

export default ResultList;
