import useGetApi from "api/useGetApi";
import { SubTitle } from "components/SubTitle";
import Chart from "components/dashboard/graph/Chart";
import st from "./DataGraph.module.scss";

export const DataGraph = () => {
  const RECENT_LIST_URL = "/dash-board/fifteen-count";
  const { data } = useGetApi("graph", RECENT_LIST_URL);

  return (
    <>
      <div className={st.data_graph_container}>
        <SubTitle title="상황별 그래프 통계" />
        <Chart className={st.chart} data={data} />
      </div>
    </>
  );
};
