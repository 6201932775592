import { create } from "zustand";
import { persist } from "zustand/middleware";
import { NavState } from "types/nav/nav";

const useNavStore = create<NavState>()(
  persist(
    (set) => ({
      activeLink: "",
      hoveredLink: "",
      setActiveLink: (link: string) => set({ activeLink: link }),
      setHoveredLink: (link: string) => set({ hoveredLink: link }),
    }),
    {
      name: "nav-storage",
    }
  )
);

export default useNavStore;
