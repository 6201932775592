import { RebuiltDataItem, TodayData } from "types/dashboard/todayData";

export const rebuildData = (datas: TodayData, isError: boolean) => {
  if (isError) {
    const newData: RebuiltDataItem[] = [
      {
        title: "당일 발송건",
        value: "-",
      },
      {
        title: "발송성공",
        value: "-",
      },
      {
        title: "즉시발송",
        value: "-",
      },
      {
        title: "예약발송",
        value: "-",
      },
      {
        title: "예약발송대기",
        value: "-",
      },
    ];

    return newData;
  } else {
    const newData: RebuiltDataItem[] = [
      {
        title: "당일 발송건",
        value: datas?.todayCount,
      },
      {
        title: "발송성공",
        value: datas?.todaySuccessCount,
      },
      {
        title: "즉시발송",
        value: datas?.todayInstantCount,
      },
      {
        title: "예약발송",
        value: datas?.todayReservationCount,
      },
      {
        title: "예약발송대기",
        value: datas?.todayWaitingCount,
      },
    ];

    return newData;
  }
};
