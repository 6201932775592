import { DataAmountBox } from "components/dashboard/DataAmountBox";

import useGetApi from "api/useGetApi";

import { RebuiltDataItem } from "types/dashboard/todayData";
import { rebuildData } from "utilities/dashboard/rebuildData";
import st from "./DataAmount.module.scss";

export const DataAmount = () => {
  const URL = "/dash-board/count";
  const { data, isError } = useGetApi("count", URL);

  const rebuiltDatas: RebuiltDataItem[] = rebuildData(data, isError);

  return (
    <div className={st.data_amount_container}>
      {rebuiltDatas.map((data) => {
        const { title, value } = data;

        return <DataAmountBox key={title} title={title} value={value} />;
      })}
    </div>
  );
};
