import useGetApi from "api/useGetApi";
import { ReactComponent as Close } from "assets/svg/x.svg";
import { CustomTable } from "components/CustomTable";
import { forwardRef, useEffect } from "react";
import useMailNumStore from "stores/statistic/useMailNumStore";
import { TableColumnProps } from "types/customTable";
import { TableRebuiltData } from "types/statistic/tableData";
import { closeDialog } from "utilities/common/closeDialog";
import { rebuildDialogData } from "utilities/statistic/rebuildDialogData";
import st from "./ReportDialog.module.scss";

interface Props {
  datas: TableRebuiltData;
}

const ReportDialog = forwardRef<HTMLDialogElement, Props>(({ datas }, ref) => {
  const { mailNum } = useMailNumStore();
  const URL = `/mail-send-list/${mailNum}/report`;
  const { data, refetch } = useGetApi("report-dialog", URL, {
    enabled: !!mailNum,
  });

  const columns: TableColumnProps[] = [
    {
      key: "category",
      label: "분류",
      flexGrow: 1,
    },
    {
      key: "successCount",
      label: "성공",
      flexGrow: 1,
    },
    {
      key: "failCount",
      label: "실패",
      flexGrow: 1,
    },
    {
      key: "totalCount",
      label: "합계",
      flexGrow: 1,
    },
  ];

  const { newData, successTotal, failureTotal, totalTotal } =
    rebuildDialogData(data);

  useEffect(() => {
    if (mailNum) {
      refetch();
    }
  }, [mailNum]);

  return (
    <>
      <dialog ref={ref} className={st.dialog}>
        <Close className={st.close_icon} onClick={() => closeDialog(ref)} />
        <h2>통계보고서</h2>
        <div className={st.box_container}>
          <div className={st.date_company_box}>
            <div>
              <span>발송날짜</span>
              <p>{datas?.sendDate}</p>
            </div>
            <div>
              <span>회사명</span>
              <p>{datas?.sendCompany}</p>
            </div>
          </div>
          <div className={st.title_box}>
            <span>제목</span>
            <p>{datas?.mailTitle}</p>
          </div>
        </div>
        <div className={st.table_container}>
          <p className={st.total_amount}>전체발송 {datas?.mailTotal}건</p>
          <div className={st.table}>
            <CustomTable
              datas={newData}
              columns={columns}
              rowKey={""}
            ></CustomTable>
          </div>
          <div className={st.total}>
            <p>합계</p>
            <p>{successTotal.toLocaleString()}건</p>
            <p>{failureTotal.toLocaleString()}건</p>
            <p>{totalTotal.toLocaleString()}건</p>
          </div>
        </div>
      </dialog>
    </>
  );
});

export default ReportDialog;
