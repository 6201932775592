import useGetApi from "api/useGetApi";

import { RebuiltDataItem } from "types/statistic/mailData";
import { rebuildData } from "utilities/statistic/rebuildData";
import st from "./DataAmount.module.scss";
import { DataAmountBox } from "./DataAmountBox";

const DataAmount = () => {
  const URL = "/mail-send-list/count";
  const { data, isError } = useGetApi("mailTotal", URL);

  const rebuiltDatas: RebuiltDataItem[] = rebuildData(data, isError);

  // 1. api 연동
  // 2. data 가공
  // 3. 가공된 데이터 할당
  // 4. 컴포넌트 생성
  // 5. 넣기

  return (
    <div className={st.data_amount_container}>
      {rebuiltDatas.map((data) => {
        const { title, value } = data;

        return <DataAmountBox key={title} title={title} value={value} />;
      })}
    </div>
  );
};

export default DataAmount;
