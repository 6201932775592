import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import { useLayoutEffect } from "react";

function Chart(props) {
  useLayoutEffect(() => {
    if (props.data) {
      let root = am5.Root.new("chartdiv");

      // Set themes
      root.setThemes([am5themes_Animated.new(root)]);

      root.dateFormatter.setAll({
        dateFormat: "yyyy-MM-dd",
        dateFields: ["valueX"],
      });

      let data = props.data;

      // Create chart
      let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          paddingLeft: 0,
          paddingRight: 0,
        })
      );

      // Create axes
      let xRenderer = am5xy.AxisRendererX.new(root, {
        minGridDistance: 50,
        strokeOpacity: 0.2,
      });
      xRenderer.grid.template.set("forceHidden", true);

      let xAxis = chart.xAxes.push(
        am5xy.DateAxis.new(root, {
          startLocation: 0,
          baseInterval: {
            timeUnit: "day",
            count: 1,
          },
          renderer: xRenderer,
          tooltip: am5.Tooltip.new(root, {}),
        })
      );

      let yRenderer = am5xy.AxisRendererY.new(root, { inside: true });
      yRenderer.grid.template.set("forceHidden", true);

      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          maxDeviation: 0,
          renderer: yRenderer,
        })
      );

      // Add series
      let series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "count",
          valueXField: "date",
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: "vertical",
            labelText: "{valueY}",
          }),
        })
      );

      series.columns.template.setAll({
        cornerRadiusTL: 15,
        cornerRadiusTR: 15,
        maxWidth: 30,
        strokeOpacity: 0,
      });

      series.columns.template.adapters.add("fill", function (fill, target) {
        if (target.dataItem.get("valueY") < 30) {
          return am5.color(0xdadada);
        }
        return fill;
      });

      // Set up data processor to parse string dates
      series.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "yyyy-MM-dd",
        dateFields: ["date"],
      });

      series.data.setAll(data);

      // add ranges
      let goalRange = yAxis.createAxisRange(
        yAxis.makeDataItem({
          value: 10,
        })
      );

      goalRange.get("grid").setAll({
        forceHidden: false,
        strokeOpacity: 0.2,
      });

      let goalLabel = goalRange.get("label");

      // reposition when width changes
      chart.plotContainer.onPrivate("width", function () {
        goalLabel.markDirtyPosition();
      });

      // Add cursor
      let cursor = chart.set(
        "cursor",
        am5xy.XYCursor.new(root, {
          alwaysShow: false,
          behavior: "none",
        })
      );
      cursor.lineY.set("visible", false);

      // Make stuff animate on load
      chart.appear(1000, 50);

      return () => {
        root.dispose();
      };
    }
  }, [props.data]);

  return <div id="chartdiv" style={{ width: "100%", height: "312px" }}></div>;
}
export default Chart;
