import { ReactComponent as Searchglass } from "assets/svg/searchGlass.svg";

import useGetApi from "api/useGetApi";
import st from "./Search.module.scss";

const Search = () => {
  const URL = `/mail-send-list`;
  const { data } = useGetApi("resultList", URL);

  return (
    <div className={st.search_container}>
      <p>{data?.totalElements}건 검색</p>
      <div className={st.search_box}>
        <input type="text" placeholder="제목을 입력해주세요" />
        <Searchglass
          className={st.search_icon}
          onClick={() => console.log("clicked")}
        />
      </div>
    </div>
  );
};

export default Search;
