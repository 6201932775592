import { ReactComponent as Logout } from "assets/svg/logout.svg";
import { ReactComponent as Menu } from "assets/svg/menu.svg";
import { ReactComponent as Notifications } from "assets/svg/notifications.svg";
import { ReactComponent as Person } from "assets/svg/person.svg";
import { ReactComponent as Settings } from "assets/svg/settings.svg";
import { removeCookie } from "utilities/cookie";

import st from "./PageTitle.module.scss";

interface Props {
  title: string;
}

export const PageTitle = ({ title }: Props) => {
  const handleLogout = () => {
    removeCookie("loginToken");
    window.location.href = "/";
  };

  return (
    <section className={st.page_title}>
      <h2 className={st.title}>{title}</h2>
      <div>
        <Notifications />
        <Settings />
        <Person />
        <Logout onClick={handleLogout} />
        <Menu className={st.menu} />
      </div>
    </section>
  );
};
