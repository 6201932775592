import { ChangeEvent } from "react";
import useLoginStore from "stores/login/useLoginStore";

import st from "./AutoLogin.module.scss";

const AutoLogin = () => {
  const { isChecked, setIsChecked } = useLoginStore();

  const handleChecked = (e: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(e.target.checked);
  };

  return (
    <div className={st.container}>
      <input
        type="checkbox"
        className={st.checkbox}
        checked={isChecked}
        onChange={handleChecked}
      />
      <p>로그인 상태 유지</p>
    </div>
  );
};

export default AutoLogin;
