import { CustomTable } from "components/CustomTable";
import { TableColumnProps } from "types/customTable";

import useGetApi from "api/useGetApi";
import { SubTitle } from "components/SubTitle";
import { recentListData } from "utilities/dashboard/recentListData";
import st from "./RecentList.module.scss";

export const RecentList = () => {
  const URL = "/mail-send-list?size=10";
  const { data, isError } = useGetApi("recentList", URL);

  const recentListDatas = data && recentListData(data.content, isError);

  const columns: TableColumnProps[] = [
    {
      key: "sendDate",
      label: "발송일",
      flexGrow: 1,
    },
    {
      key: "sendCompany",
      label: "발송회사",
      flexGrow: 1,
    },
    {
      key: "groupName",
      label: "발송그룹",
      flexGrow: 1,
    },
    {
      key: "mailTitle",
      label: "제목",
      flexGrow: 1,
    },
    {
      key: "mailTotal",
      label: "발송건수",
      flexGrow: 1,
    },
    {
      key: "succMailTotal",
      label: "성공건수",
      flexGrow: 1,
    },
    {
      key: "failMailTotal",
      label: "실패건수",
      flexGrow: 1,
    },
  ];

  return (
    <div className={st.table_container}>
      <SubTitle title="최근리스트" />
      <CustomTable
        columns={columns}
        rowKey="title"
        datas={recentListDatas && recentListDatas}
      ></CustomTable>
    </div>
  );
};
