import { MailData, RebuiltDataItem } from "types/statistic/mailData";

export const rebuildData = (datas: MailData, isError: boolean) => {
  if (isError) {
    const newData: RebuiltDataItem[] = [
      {
        title: "전체메일",
        value: "-",
      },
      {
        title: "성공메일",
        value: "-",
      },
      {
        title: "실패메일",
        value: "-",
      },
    ];

    return newData;
  } else {
    const newData: RebuiltDataItem[] = [
      {
        title: "전체메일",
        value: datas?.mailTotal,
      },
      {
        title: "성공메일",
        value: datas?.succMailTotal,
      },
      {
        title: "실패메일",
        value: datas?.failMailTotal,
      },
    ];
    return newData;
  }
};
