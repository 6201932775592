import Pagination from "rsuite/Pagination";

import "./Paging.less";

interface PagingProps {
  total: number;
  limit: number;
  activePage: number;
  onChangePage: (page: number) => void;
}

const MAX_PAGE_BUTTONS = 5;
const PAGING_BUTTON_SIZE = "md" as const;

export const Paging = ({
  total,
  limit,
  activePage,
  onChangePage,
}: PagingProps) => {
  const settings = {
    size: PAGING_BUTTON_SIZE,
    maxButtons: MAX_PAGE_BUTTONS,
    prev: true,
    next: true,
    total,
    limit,
    activePage,
    onChangePage,
  };

  return <Pagination {...settings}></Pagination>;
};
