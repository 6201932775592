import Logo from "assets/image/logo.png";
import BlueChartBar from "assets/svg/blueChartBar.svg";
import BlueHome from "assets/svg/blueHome.svg";
import BlueMail from "assets/svg/blueMail.svg";
import BlueMember from "assets/svg/blueMember.svg";
import ChartBar from "assets/svg/chartbar.svg";
import Home from "assets/svg/home.svg";
import LogoLine from "assets/svg/logoLine.svg";
import Mail from "assets/svg/mail.svg";
import Member from "assets/svg/member.svg";
import { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import useNavStore from "stores/nav/useNavStore";
import st from "./Nav.module.scss";

export const Nav = () => {
  const location = useLocation();
  const { activeLink, hoveredLink, setActiveLink, setHoveredLink } =
    useNavStore();

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname, setActiveLink]);

  const navLinks = [
    { img: Home, hoverImg: BlueHome, path: "/", label: "대시보드" },
    { img: Mail, hoverImg: BlueMail, path: "/send-list", label: "발송리스트" },
    {
      img: Member,
      hoverImg: BlueMember,
      path: "/member-list",
      label: "회원리스트",
    },
    {
      img: ChartBar,
      hoverImg: BlueChartBar,
      path: "/send-stats",
      label: "발송통계",
    },
  ];

  return (
    <div>
      <div className={st.logo_container}>
        <img src={Logo} alt="Logo" />
      </div>
      <img className={st.line} src={LogoLine} alt="line" />
      <nav className={st.nav_container}>
        {navLinks.map((link) => (
          <NavLink
            key={link.path}
            to={link.path}
            className={`${st.link} ${
              activeLink === link.path ? st.active : ""
            }`}
            onClick={() => setActiveLink(link.path)}
            onMouseEnter={() => setHoveredLink(link.path)}
            onMouseLeave={() => setHoveredLink("")}
          >
            <img
              src={
                activeLink === link.path || hoveredLink === link.path
                  ? link.hoverImg || link.img
                  : link.img
              }
              alt={link.label}
              className={st.nav_icon}
            />
            <span className={st.link_text}>{link.label}</span>
          </NavLink>
        ))}
      </nav>
    </div>
  );
};
