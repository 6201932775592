import { DialogTableData } from "types/statistic/dialogTableData";

export const rebuildDialogData = (datas: DialogTableData[]) => {
  const newData: DialogTableData[] = [];
  let successTotal: number = 0;
  let failureTotal: number = 0;
  let totalTotal: number = 0;

  if (!datas || datas?.length === 0) {
    newData.push({
      category: "-",
      successCount: "-",
      failCount: "-",
      totalCount: "-",
    });
  } else {
    datas.map((item: DialogTableData) => {
      newData.push({
        category: item?.category,
        successCount: item?.successCount.toLocaleString(),
        failCount: item?.failCount.toLocaleString(),
        totalCount: item?.totalCount.toLocaleString(),
      });
      successTotal += item?.successCount as number;
      failureTotal += item?.failCount as number;
      totalTotal += item?.totalCount as number;
    });
  }

  return { newData, successTotal, failureTotal, totalTotal };
};
