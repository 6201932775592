import React, { ReactElement, ReactNode } from "react";
import Table from "rsuite/Table";
import {
  ColumnStyleSetting,
  TableColumnProps,
  TableColumnValueProps,
  TableSettings,
} from "types/customTable";

import "./CustomTable.less";

const { Column, HeaderCell, Cell } = Table;
const HEADER_HEIGHT = 52;
const ROW_HEIGHT = 52;
const HEADER_HEIGHT_IN_MODAL = 33;

const getCustomCell = (children: ReactNode, key: string) => {
  return React.Children.toArray(children).find(
    (child) => React.isValidElement(child) && child.key === `.$${key}`
  );
};

const getCloneElement = (
  customCell: ReactElement,
  text: string,
  id: string
) => {
  return React.cloneElement(customCell, { text, id });
};

const getClickOptions = (
  handleClick: ((id: string) => void) | undefined,
  rowKey: string
) => {
  return handleClick
    ? { onClick: () => handleClick(rowKey), style: { cursor: "pointer" } }
    : {};
};

export const CustomTable = ({
  datas,
  columns,
  children,
  rowKey,
  type,
}: TableColumnValueProps) => {
  const tableSettings: TableSettings = {
    autoHeight: true,
    hover: false,
    headerHeight: type ? HEADER_HEIGHT_IN_MODAL : HEADER_HEIGHT,
    rowHeight: ROW_HEIGHT,
  };

  return (
    <>
      {!type && (
        <div className="table_container">
          <Table data={datas} rowKey={rowKey} {...tableSettings}>
            {columns.map((column, idx) => {
              const {
                key,
                label,
                isCustomCell,
                handleClick,
                ...rest
              }: TableColumnProps = column;
              const columnSettings: ColumnStyleSetting = {
                verticalAlign: "middle",
                align: rest.align ? rest.align : "center",
                ...rest,
              };

              return (
                <Column key={column.key} {...columnSettings}>
                  <HeaderCell>{label}</HeaderCell>
                  <Cell dataKey={key}>
                    {(data) => {
                      const clickOptions = getClickOptions(
                        handleClick,
                        data[rowKey]
                      );

                      return isCustomCell ? (
                        <div {...clickOptions}>
                          {getCloneElement(
                            getCustomCell(children, key) as ReactElement,
                            data[key],
                            data[rowKey]
                          )}
                        </div>
                      ) : (
                        <div {...clickOptions}>{data[key] ?? "-"}</div>
                      );
                    }}
                  </Cell>
                </Column>
              );
            })}
          </Table>
        </div>
      )}
      {type && (
        <div className="table_container_in_modal">
          <Table data={datas} rowKey={rowKey} {...tableSettings}>
            {columns.map((column, idx) => {
              const {
                key,
                label,
                isCustomCell,
                handleClick,
                ...rest
              }: TableColumnProps = column;
              const columnSettings: ColumnStyleSetting = {
                verticalAlign: "middle",
                align: rest.align ? rest.align : "center",
                ...rest,
              };

              return (
                <Column key={column.key} {...columnSettings}>
                  <HeaderCell>{label}</HeaderCell>
                  <Cell dataKey={key}>
                    {(data) => {
                      const clickOptions = getClickOptions(
                        handleClick,
                        data[rowKey]
                      );

                      return isCustomCell ? (
                        <div {...clickOptions}>
                          {getCloneElement(
                            getCustomCell(children, key) as ReactElement,
                            data[key],
                            data[rowKey]
                          )}
                        </div>
                      ) : (
                        <div {...clickOptions}>{data[key] ?? "-"}</div>
                      );
                    }}
                  </Cell>
                </Column>
              );
            })}
          </Table>
        </div>
      )}
    </>
  );
};
