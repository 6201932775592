import { DataAmount } from "components/dashboard/container/DataAmount";
import { DataGraph } from "components/dashboard/container/DataGraph";
import st from "./Information.module.scss";

export const Information = () => {
  return (
    <section className={st.information}>
      <DataAmount />
      <DataGraph />
    </section>
  );
};
