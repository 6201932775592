import { Nav } from "layout/navbar/Nav";
import Login from "pages/login/Login";
import { Outlet } from "react-router-dom";
import { getCookie } from "utilities/cookie";

import st from "./Root.module.scss";

const Root = () => {
  const cookie = getCookie("loginToken");

  return (
    <section className={st.section}>
      {!cookie && <Login />}
      {cookie && (
        <div className={st.container}>
          <div id={st.menu_container}>
            <Nav />
          </div>
          <div id={st.outlet_container}>
            <Outlet />
          </div>
        </div>
      )}
    </section>
  );
};

export default Root;
